import { Box } from '@chakra-ui/react';
import { generalUtils } from '@shared/common';
import { forwardRef } from 'react';

import { useProducts } from '../../hooks/useProducts';
import { TransText } from '../TransText';

export const PricePerDay = forwardRef((props) => {
  const { initialPlan, currency, findProductById, findPriceByCurrency } = useProducts();
  const product = findProductById(initialPlan[6]);
  const price = findPriceByCurrency(product?.prices, currency);

  return (
    <TransText
      transKey='plans:only_x_per_day'
      components={{ span: <Box as='span' display={{ base: 'block', md: 'initial' }} /> }}
      values={{
        value: generalUtils.formatPrice(product ? price.finalPrice / (product.plan.interval * 30) : 0, currency),
      }}
      {...props}
    />
  );
});

PricePerDay.displayName = 'PricePerDay';
