import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { generalUtils } from '@shared/common';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef } from 'react';

import { useAppLead } from '~/hooks/useAppLead';
import { SINGLE_PRODUCT_COUPONS, useAppParams } from '~/hooks/useAppParams';
import { useProducts } from '~/hooks/useProducts';

import { useAppRouter } from '../hooks/useAppRouter';

import { TransText } from './TransText';

export const OrderSummary = () => {
  const { selectedPlan: plan } = useAppLead();
  const { findTrialPrice, findProductById } = useProducts();
  const selectedPlan = plan || findProductById('21');
  const { finalPrice, price, currency } = useProducts(selectedPlan);
  const trialPrice = findTrialPrice(selectedPlan);
  const orderSummaryInitialPrice = trialPrice.price || price;
  const orderSummaryFinalPrice = trialPrice.finalPrice || finalPrice;

  return (
    <Box marginBottom='1.5rem'>
      <Flex alignItems='center' justifyContent='space-between' gap='1rem' marginBottom='0.5rem'>
        <Text flex={1} fontSize='14px' color='#060654' maxWidth='16rem'>
          {`Digesti ${selectedPlan?.name}`}
        </Text>
        <Text fontSize='14px' color='#060654' width='90px' textAlign='right'>
          {generalUtils.formatPrice(orderSummaryInitialPrice, currency)}
        </Text>
      </Flex>
      {selectedPlan?.id === '30' || selectedPlan?.id === '70' ? (
        <Flex justifyContent='space-between' gap='0.5rem' marginBottom='0.5rem'>
          <Text fontSize='14px' color='primaryDark' fontWeight={400}>
            Additional 1 month
          </Text>
          <Text fontSize='14px' color='primaryDark' fontWeight={400}>
            FREE
          </Text>
        </Flex>
      ) : null}
      <Flex justifyContent='space-between' gap='0.5rem'>
        <Text fontSize='14px' color='system.error' fontWeight={400}>{`Discount (${(
          100 -
          (orderSummaryFinalPrice / orderSummaryInitialPrice) * 100
        ).toFixed(0)}%)`}</Text>
        <Text fontSize='14px' color='system.error' fontWeight={400}>
          {`-${generalUtils.formatPrice(orderSummaryInitialPrice - orderSummaryFinalPrice, currency)}`}
        </Text>
      </Flex>

      <Flex
        justifyContent='space-between'
        gap='0.5rem'
        lineHeight='100%'
        paddingTop='1rem'
        marginTop='1rem'
        borderTop='1px solid rgba(0, 0, 0, 0.1)'
      >
        <Text fontSize='14px' color='#060654' fontWeight={500}>
          Total
        </Text>
        <Text fontSize='18px' color='#060654' fontWeight={700} lineHeight='100%'>
          {generalUtils.formatPrice(orderSummaryFinalPrice, currency)}
        </Text>
      </Flex>
    </Box>
  );
};

export const OrderSummarySimple = forwardRef((props: BoxProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { coupon } = useAppParams();
  const { t } = useTranslation();
  const { selectedPlan: plan } = useAppLead();
  const { findProductById, findTrialPrice, findProductPrice } = useProducts();
  const { funnelName } = useAppRouter();
  const selectedPlan = plan || findProductById('21');
  const { finalPrice, currency, price } = selectedPlan?.trial?.prices
    ? findTrialPrice(selectedPlan)
    : findProductPrice(selectedPlan);
  const is7DaysTrial = selectedPlan?.trial?.period_days === 7;
  const isDiscounted = price !== finalPrice;
  const couponDiscountPrecents = coupon && coupon.match(/\d{2}/)?.[0] ? Number(coupon.match(/\d{2}/)?.[0] || 20) : 0;
  const couponDiscountAmount = couponDiscountPrecents
    ? Math.abs(finalPrice - finalPrice / (1 - couponDiscountPrecents / 100))
    : 0;
  const totalDiscountAmount = price - finalPrice - couponDiscountAmount;

  return (
    <Box ref={ref} padding='1.5rem 1rem' bg='#fff' borderRadius='0.5rem' border='1px solid #E1E1E1' {...props}>
      <Flex justifyContent='space-between' marginBottom='0.5rem' textTransform={is7DaysTrial ? 'uppercase' : 'initial'}>
        <Text
          fontSize='14px'
          fontWeight={500}
        >{`${t(is7DaysTrial ? 'common:x_day_plan' : 'common:x_months_plan', { count: selectedPlan?.plan.interval, interval: is7DaysTrial ? 7 : selectedPlan?.plan.interval })}`}</Text>
        <Text fontSize='14px' textDecor={isDiscounted ? 'line-through' : 'initial'}>
          {generalUtils.formatPrice(isDiscounted ? price : finalPrice, {
            currency,
          })}
        </Text>
      </Flex>

      {isDiscounted ? (
        <>
          <Flex marginTop='0.75rem' justifyContent='space-between'>
            <Text color='system.error' fontSize='14px'>
              {coupon && SINGLE_PRODUCT_COUPONS.includes(coupon)
                ? 'Coupon discount'
                : funnelName === 'cortisol-bf'
                  ? t('plans:campaigns:black_friday_discount')
                  : t('common:discount')}{' '}
              (-
              {((totalDiscountAmount / price) * 100).toFixed(0)}%)
            </Text>
            <Text color='system.error' fontSize='14px'>
              -{generalUtils.formatPrice(totalDiscountAmount, currency)}
            </Text>
          </Flex>
          {coupon && couponDiscountAmount ? (
            <Flex marginTop='0.75rem' justifyContent='space-between'>
              <Text color='system.error' fontSize='14px'>
                Coupon discount (-{couponDiscountPrecents}%)
              </Text>
              <Text color='system.error' fontSize='14px'>
                -{generalUtils.formatPrice(couponDiscountAmount, currency)}
              </Text>
            </Flex>
          ) : null}
        </>
      ) : null}
      <Flex justifyContent='space-between' marginTop='1rem' paddingTop='1rem' borderTop='1px solid #E1E1E1'>
        <TransText transKey='common:total' fontSize='18px' fontWeight={700}></TransText>
        <Text fontSize='18px' fontWeight={700}>
          {generalUtils.formatPrice(finalPrice, currency)}
        </Text>
      </Flex>
      {coupon ? (
        <Flex justifyContent='space-between' marginTop='0.75rem'>
          <Text fontSize='13px' color='rgba(0, 0, 0, 0.6)'>
            Coupon applied
          </Text>
          <Text
            fontWeight={300}
            lineHeight='100%'
            padding='6px 12px'
            bg='rgba(0, 0, 0, 0.1)'
            borderRadius='1rem'
            fontSize='13px'
            textTransform='uppercase'
            color='black'
          >
            {coupon}
          </Text>
        </Flex>
      ) : null}
    </Box>
  );
});

OrderSummarySimple.displayName = 'OrderSummarySimple';
