import { Button, Flex } from '@chakra-ui/react';

import { useAnalytics } from '~/hooks/useAnalytics';
import { useAppRouter } from '~/hooks/useAppRouter';

import { useAppLead } from '../hooks/useAppLead';

export const CortisolGenderSelection = () => {
  const { updateQuizAnswers } = useAppLead();
  const analytics = useAnalytics();
  const { pushNextRoute } = useAppRouter('/quiz');

  const handleSelection = (gender: 'female' | 'male') => {
    updateQuizAnswers({ gender });

    analytics.log('quiz_question_answer', { category: 'gender', label: gender });

    pushNextRoute();
  };

  return (
    <Flex gap={{ base: '0.5rem', md: '1rem' }} width='100%' flexDir={{ base: 'column', md: 'row' }}>
      <Button display='inline-block' flex={1} gap='1rem' onClick={() => handleSelection('male')} cursor='pointer'>
        👩 Plan for women
      </Button>
      <Button
        display='inline-block'
        flex={1}
        bg='black'
        color='white'
        _hover={{ bg: 'black' }}
        gap='1rem'
        onClick={() => handleSelection('female')}
        cursor='pointer'
      >
        👨 Plan for men
      </Button>
    </Flex>
  );
};
