import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import Image from 'next/image';

import ArrowSVG from '~/assets/icons/arrow.svg';
import Age1833Image from '~/assets/images/age_18_33.png';
import Age3344Image from '~/assets/images/age_33_44.png';
import Age4455Image from '~/assets/images/age_44_55.png';
import Age55Image from '~/assets/images/age_55.png';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useAppRouter } from '~/hooks/useAppRouter';

import { useAppLead } from '../hooks/useAppLead';

export const AgeSelection = () => {
  const { updateQuizAnswers } = useAppLead();
  const analytics = useAnalytics();
  const { pushNextRoute } = useAppRouter('/quiz');

  const handleSelection = (ageRange: '18-33' | '33-44' | '44-55' | '55+') => {
    updateQuizAnswers({ ageRange });

    analytics.log('quiz_question_answer', { category: 'ageRange', label: ageRange });

    pushNextRoute();
  };

  return (
    <Grid
      gap={{ base: '0.5rem', md: '1rem' }}
      width='100%'
      gridTemplateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
    >
      <Box
        padding='0.5rem'
        borderRadius='0.25rem'
        backgroundColor='system.white'
        cursor='pointer'
        onClick={() => handleSelection('18-33')}
      >
        <Flex
          position='relative'
          marginBottom='0.5rem'
          paddingBottom='110%'
          borderRadius='0.25rem'
          overflow='hidden'
          backgroundColor='#eee'
        >
          <Image src={Age1833Image} fill alt='' style={{ objectFit: 'cover' }} />
        </Flex>
        <Flex
          backgroundColor='system.yellow'
          padding='0.5rem'
          gap='1.25rem'
          borderRadius='0.25rem'
          justifyContent='center'
          alignItems='center'
        >
          <Text fontWeight={700}>18-33</Text>
          <ArrowSVG />
        </Flex>
      </Box>
      <Box
        padding='0.5rem'
        borderRadius='0.25rem'
        backgroundColor='system.white'
        cursor='pointer'
        onClick={() => handleSelection('33-44')}
      >
        <Flex
          position='relative'
          marginBottom='0.5rem'
          paddingBottom='110%'
          borderRadius='0.25rem'
          overflow='hidden'
          backgroundColor='#eee'
        >
          <Image src={Age3344Image} fill alt='' style={{ objectFit: 'cover' }} />
        </Flex>
        <Flex
          backgroundColor='system.yellow'
          padding='0.5rem'
          gap='1.25rem'
          borderRadius='0.25rem'
          justifyContent='center'
          alignItems='center'
        >
          <Text fontWeight={700}>33-44</Text>
          <ArrowSVG />
        </Flex>
      </Box>
      <Box
        padding='0.5rem'
        borderRadius='0.25rem'
        backgroundColor='system.white'
        cursor='pointer'
        onClick={() => handleSelection('44-55')}
      >
        <Flex
          position='relative'
          marginBottom='0.5rem'
          paddingBottom='110%'
          borderRadius='0.25rem'
          overflow='hidden'
          backgroundColor='#eee'
        >
          <Image src={Age4455Image} fill alt='' style={{ objectFit: 'cover' }} />
        </Flex>
        <Flex
          backgroundColor='system.yellow'
          padding='0.5rem'
          gap='1.25rem'
          borderRadius='0.25rem'
          justifyContent='center'
          alignItems='center'
        >
          <Text fontWeight={700}>44-55</Text>
          <ArrowSVG />
        </Flex>
      </Box>
      <Box
        padding='0.5rem'
        borderRadius='0.25rem'
        backgroundColor='system.white'
        cursor='pointer'
        onClick={() => handleSelection('55+')}
      >
        <Flex
          position='relative'
          marginBottom='0.5rem'
          paddingBottom='110%'
          borderRadius='0.25rem'
          overflow='hidden'
          backgroundColor='#eee'
        >
          <Image src={Age55Image} fill alt='' style={{ objectFit: 'cover' }} />
        </Flex>
        <Flex
          backgroundColor='system.yellow'
          padding='0.5rem'
          gap='1.25rem'
          borderRadius='0.25rem'
          justifyContent='center'
          alignItems='center'
        >
          <Text fontWeight={700}>55+</Text>
          <ArrowSVG />
        </Flex>
      </Box>
    </Grid>
  );
};
