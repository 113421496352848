import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  keyframes,
  useDisclosure,
} from '@chakra-ui/react';
import { generalUtils } from '@shared/common';

import { useAnalytics } from '~/hooks/useAnalytics';
import { Product, useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useProducts } from '~/hooks/useProducts';

const firstPulse = keyframes`
  0% {box-shadow: 0 0 0 0 #000;}
  20% {box-shadow: transparent 0px 0px 0px 14px}
`;

const secondPulse = keyframes`
  20% {box-shadow: 0 0 0 0 #000;}
  40% {box-shadow: transparent 0px 0px 0px 14px}
`;

const thirdPulse = keyframes`
  40% {box-shadow: 0 0 0 0 #000;}
  60% {box-shadow: transparent 0px 0px 0px 14px}
`;

export const PlanSelection = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { pushNextRoute } = useAppRouter('/checkout');
  const { findProductById, findProductPrice } = useProducts();
  const { updateUser } = useAppLead();
  const analytics = useAnalytics();

  const handleClick = (product: Product) => {
    updateUser({ selectedPlan: product });

    if (product) {
      analytics.log('plans_select', { category: product?.name, label: product.name });
    }

    pushNextRoute();
  };

  const handleMonthPlanSelection = (product: Product) => {
    updateUser({ selectedPlan: product });

    if (product) {
      analytics.log('plans_select', { category: product?.name, label: product.name });
    }

    onOpen();
  };

  return (
    <>
      {(() => {
        const product = findProductById('21');
        const price = findProductPrice(product);

        return (
          <Box
            padding={{ base: '1rem', md: '1.25rem 1.5rem' }}
            border='2px solid'
            borderColor='#efefef'
            borderRadius='0.5rem'
            width='100%'
            backgroundColor='system.white'
          >
            {product ? (
              <>
                <Flex
                  gap={{ base: '1.5rem', md: '1.5rem' }}
                  justifyContent='space-between'
                  alignItems='center'
                  marginBottom='1rem'
                >
                  <Flex justifyContent='center' position='absolute' top='-0.75rem' left='0' right='0' zIndex={10}>
                    <Text
                      display='inline-block'
                      padding='0.25rem 1rem'
                      borderRadius='8px'
                      backgroundColor='#5B9255'
                      color='white'
                      fontWeight={500}
                      fontSize='11px'
                      textAlign='center'
                    >
                      BEST VALUE: 76% OFF
                    </Text>
                  </Flex>
                  <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
                    <Box>
                      <Text
                        fontWeight={700}
                        fontSize={{ base: '20px', md: '24px' }}
                        marginBottom={{ base: '0.25rem', md: '0.5rem' }}
                        lineHeight='100%'
                      >
                        {product.name}
                      </Text>
                      <Flex gap='0.5rem' alignItems='center'>
                        <Text
                          fontSize={{ base: '12px', md: '14px' }}
                          color='system.error'
                          textDecoration='line-through'
                        >
                          {generalUtils.formatPrice(price.price, price.currency)}
                        </Text>
                        <Text fontSize={{ base: '12px', md: '14px' }} color='rgba(5, 5, 23, 0.64)'>
                          {generalUtils.formatPrice(price.finalPrice, price.currency)}
                        </Text>
                      </Flex>
                      {/* <Text color='rgba(5, 5, 23, 0.64)' fontSize='14px'>
                  Billed every 3 months
                </Text> */}
                    </Box>
                  </Flex>
                  <Box textAlign='center'>
                    <Text textDecoration='line-through' fontSize='14px' color='rgba(5, 5, 23, 0.64)'>
                      {generalUtils.formatPrice(price.price / 180, price.currency)}
                    </Text>
                    <Text fontSize={{ base: '24px', md: '30px' }} fontWeight={900} lineHeight='120%'>
                      {generalUtils.formatPrice(price.finalPrice / 180, price.currency)}
                    </Text>
                    <Text color='rgba(5, 5, 23, 0.64)' fontSize='14px'>
                      per day
                    </Text>
                  </Box>
                </Flex>
                <Button
                  width='100%'
                  gap='1rem'
                  onClick={() => handleClick(product)}
                  animation={`10s ease 0s infinite normal none running ${firstPulse}`}
                >
                  Claim my plan
                </Button>
              </>
            ) : (
              <Flex justifyContent='center'>
                <Spinner />
              </Flex>
            )}
          </Box>
        );
      })()}
      {(() => {
        const product = findProductById('22');
        const price = findProductPrice(product);

        return (
          <Box
            border='2px solid'
            borderColor='#efefef'
            borderRadius='0.5rem'
            width='100%'
            backgroundColor='system.white'
            padding={{ base: '1rem', md: '1.25rem 1.5rem' }}
            position='relative'
          >
            {product ? (
              <>
                <Flex
                  gap={{ base: '1.5rem', md: '1.5rem' }}
                  justifyContent='space-between'
                  alignItems='center'
                  marginBottom={{ base: '1rem', md: '1rem' }}
                >
                  <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
                    <Box>
                      <Text
                        fontWeight={700}
                        fontSize={{ base: '20px', md: '24px' }}
                        marginBottom={{ base: '0.25rem', md: '0.375rem' }}
                        lineHeight='100%'
                      >
                        {product.name}
                      </Text>
                      <Flex gap='0.5rem' alignItems='center '>
                        <Text
                          fontSize={{ base: '12px', md: '14px' }}
                          color='system.error'
                          textDecoration='line-through'
                        >
                          {generalUtils.formatPrice(price.price, price.currency)}
                        </Text>
                        <Text fontSize={{ base: '12px', md: '14px' }} color='rgba(5, 5, 23, 0.64)'>
                          {generalUtils.formatPrice(price.finalPrice, price.currency)}
                        </Text>
                      </Flex>
                      {/* <Text color='rgba(5, 5, 23, 0.64)' fontSize='14px'>
                  Billed every 6 months
                </Text> */}
                    </Box>
                  </Flex>
                  <Box textAlign='center'>
                    <Text textDecoration='line-through' fontSize='14px' color='rgba(5, 5, 23, 0.64)'>
                      {generalUtils.formatPrice(price.price / 90, price.currency)}
                    </Text>
                    <Text fontSize={{ base: '24px', md: '30px' }} fontWeight={900} lineHeight='120%'>
                      {generalUtils.formatPrice(price.finalPrice / 90, price.currency)}
                    </Text>
                    <Text color='rgba(5, 5, 23, 0.64)' fontSize='14px'>
                      per day
                    </Text>
                  </Box>
                </Flex>
                <Button
                  width='100%'
                  gap='1rem'
                  onClick={() => handleClick(product)}
                  animation={`10s ease 0s infinite normal none running ${secondPulse}`}
                >
                  Claim my plan
                </Button>
              </>
            ) : (
              <Flex justifyContent='center'>
                <Spinner />
              </Flex>
            )}
          </Box>
        );
      })()}
      {(() => {
        const product = findProductById('23');
        const fourMonthProduct = findProductById('30');
        const price = findProductPrice(product);
        const fourMonthPrice = findProductPrice(fourMonthProduct);

        return (
          <Box
            padding={{ base: '1rem', md: '1.25rem 1.5rem' }}
            border='2px solid'
            borderColor='#efefef'
            borderRadius='0.5rem'
            width='100%'
            backgroundColor='system.white'
          >
            {product ? (
              <>
                <Flex
                  gap={{ base: '1.5rem', md: '1.5rem' }}
                  justifyContent='space-between'
                  alignItems='center'
                  marginBottom='1rem'
                >
                  <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
                    <Box>
                      <Text
                        fontWeight={700}
                        fontSize={{ base: '20px', md: '24px' }}
                        marginBottom={{ base: '0.25rem', md: '0.5rem' }}
                        lineHeight='100%'
                      >
                        {product.name}
                      </Text>
                      <Flex gap='0.5rem' alignItems='center'>
                        <Text
                          fontSize={{ base: '12px', md: '14px' }}
                          color='system.error'
                          textDecoration='line-through'
                        >
                          {generalUtils.formatPrice(price.price, price.currency)}
                        </Text>
                        <Text fontSize={{ base: '12px', md: '14px' }} color='rgba(5, 5, 23, 0.64)'>
                          {generalUtils.formatPrice(price.finalPrice, price.currency)}
                        </Text>
                      </Flex>
                      {/* <Text color='rgba(5, 5, 23, 0.64)' fontSize='14px'>
                  Billed every month
                </Text> */}
                    </Box>
                  </Flex>
                  <Box textAlign='center'>
                    <Text textDecoration='line-through' fontSize='14px' color='rgba(5, 5, 23, 0.64)'>
                      {generalUtils.formatPrice(price.price / 30, price.currency)}
                    </Text>
                    <Text fontSize={{ base: '24px', md: '30px' }} fontWeight={900} lineHeight='120%'>
                      {generalUtils.formatPrice(price.finalPrice / 30, price.currency)}
                    </Text>
                    <Text color='rgba(5, 5, 23, 0.64)' fontSize='14px'>
                      per day
                    </Text>
                  </Box>
                </Flex>
                <Button
                  width='100%'
                  gap='1rem'
                  onClick={() => handleMonthPlanSelection(product)}
                  animation={`10s ease 0s infinite normal none running ${thirdPulse}`}
                >
                  Claim my plan
                </Button>
                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                  <ModalOverlay />
                  <ModalContent padding='2rem 1rem' borderRadius={{ base: '0', md: 'md' }}>
                    <Text
                      textAlign='center'
                      marginBottom='1.5rem'
                      fontWeight={700}
                      color='rgba(0, 0, 0, 0.84)'
                      fontSize='24px'
                      lineHeight='140%'
                    >
                      Pay for 3 months.
                      <br />
                      We&apos;ll give you{' '}
                      <Box as='span' color='#3ba530'>
                        1 for free
                      </Box>
                      .
                    </Text>
                    <Button
                      marginBottom='1rem'
                      onClick={() => handleClick(fourMonthProduct as Product)}
                    >{`Get 4 months for ${generalUtils.formatPrice(
                      fourMonthPrice.finalPrice / 120,
                      fourMonthPrice.currency,
                    )}/day`}</Button>
                    <Text
                      textAlign='center'
                      fontSize='13px'
                      color='rgba(0, 0, 0, 0.6)'
                      onClick={() => pushNextRoute()}
                      cursor='pointer'
                    >
                      {`Continue with 1 month plan for ${generalUtils.formatPrice(
                        price.finalPrice / 30,
                        price.currency,
                      )}/day`}
                    </Text>
                  </ModalContent>
                </Modal>
              </>
            ) : (
              <Flex justifyContent='center'>
                <Spinner />
              </Flex>
            )}
          </Box>
        );
      })()}
    </>
  );
};
