import { TextProps } from '@chakra-ui/react';
import { useDate } from '@shared/common';
import summarize from '@shared/summarize';
import { FC } from 'react';

import { useAppLead } from '~/hooks/useAppLead';

import { TransText } from './TransText';

export const ShortWeightLossTitle: FC<TextProps> = (props) => {
  const { quizAnswers } = useAppLead();
  const { addDays } = useDate();
  const summary = summarize({
    weight: Number(quizAnswers?.weight),
    targetWeight: Number(quizAnswers?.targetWeight),
  });

  return (
    <TransText
      {...props}
      transKey='summary:headlines:you_can_lose_weight'
      values={{
        finalDate: `${addDays(14).month} ${addDays(14).newDate.getUTCDate()}`,
        weight: summary.forecast.afterFirstTwoWeeks.toFixed(0),
      }}
    ></TransText>
  );
};

ShortWeightLossTitle.displayName = 'ShortWeightLossTitle';
